import React from 'react';
import MainImage from "./MainImage";
import { getSpacerClass } from '../utils/helpers';

const StatsBlock = ({ data }) => {
  const { heading, stats, spacer } = data;
  return (
    <div className={getSpacerClass(spacer)}>
      <div className="row align-justify">
        <div className="col-xs-18 col-md-14 col-lg-12 col-xl-10 align-center text-center">
          <h2 className="text-blue">
            If you need your message heard, you need Text In Church.
          </h2>
        </div>
      </div>
      <div className="row text-statistics">
        { stats.map(i => (
          <div key={i._key} className="col-xs-18 col-md-6">
            <div className="stat-block">
              <MainImage mainImage={i.illustration.image} />
              <h2>{i.heading}</h2>
              <p>{i.tagline}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StatsBlock;
