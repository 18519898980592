import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import Img  from 'gatsby-image';
import CTA from '../components/CTA';
import MainImage from "./MainImage";
import { getFluidProps } from '../utils/image-url';

const Hero = ({ data }) => {
  const { heading, tagline, cta, illustration, } = data;
  return (
    <div className="row shape-background shape-background-dots shape-background-right">
      <div className="col-xs-18 col-md-10 col-md-offset-1 align-justify">
        <h1 className="text-blue">
          {heading}
        </h1>
        <h3>{tagline}</h3>
        { cta && (
          <p>
            <CTA {...cta} size="xl" />
          </p>
        )}
      </div>
      <div className="col-xs-18 col-md-7">
        <div className="shape-img-right">
          <Img fluid={getFluidProps(illustration.image.asset)} />
          {/*<MainImage mainImage={illustration.image} />*/}
        </div>
      </div>
    </div>
  )
};

export default Hero;
